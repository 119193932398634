<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="server.connection"
          >Server Mongo Queries: {{ server.connection.database !== null ? server.connection.database.toUpperCase() : '-' }}</v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text @click="addQueryDialog = true">
            Adicionar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- queries -->
      <v-container v-if="server.configuration">
        <div v-for="(value, key) in server.configuration.sqls" :key="key" class="box">
          <v-card-title class="pa-0 mt-4 mx-2">{{ key }}</v-card-title>
          <importing-sql-btn @save="saveEditManager(key)" @delete="deleteEditManager(key)" />
          <v-switch v-model="server.configuration.sqls[key].inherit" class="ml-3 my-2" hide-details label="Herdar" />
          <v-spacer />
          <query-builder-mongo
            :editable="true"
            :show="server.configuration.sqls[key].inherit"
            :sql.sync="server.configuration.sqls[key]"
            class="mb-5 ml-3 mt-3"
            itemscope
          />
          <v-list class="mt-4 ml-2">
            <v-list-item-title><strong>FIELDS_OUTPUT_ORDER</strong></v-list-item-title>
            <v-btn class="my-2 ml-0" color="primary" outlined @click="openFooDialog(key)"><v-icon class="mr-1">mdi-plus</v-icon> EDITAR/ADICIONAR CAMPO</v-btn>
          </v-list>
          <v-divider />
        </div>
        <change-dialog v-if="changesDialogEdit" :dialog.sync="changesDialogEdit" :message.sync="changesMessage" @proceed="saveQuery(key)" />
        <change-dialog v-if="changesDialogDelete" :dialog.sync="changesDialogDelete" :message.sync="changesMessage" @proceed="deleteQuery(key)" />
      </v-container>
    </v-card>

    <!-- fields output order modal -->
    <v-dialog v-if="fooDialog" v-model="fooDialog" max-width="700" persistent transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="closeFooDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Ordem de Saída das Propriedades - {{ getSafe(() => key.toUpperCase()) }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-container class="pa-6">
          <div v-if="server.configuration.sqls[key].query && server.configuration.sqls[key].query.fields_output_order" class="mb-4">
            <v-card-title class="pl-0 py-0">Propriedades:</v-card-title>
            <v-text-field
              v-for="value in server.configuration.sqls[key].query.fields_output_order"
              :key="value.index"
              readonly
              class="my-2"
              outlined
              hide-details
              :value="value.toUpperCase()"
            >
              <template v-slot:append-outer>
                <v-icon color="error" @click="deleteField(key, value, 'delete')">mdi-delete</v-icon>
              </template>
            </v-text-field>
          </div>
          <v-alert v-else type="info" dense outlined>Não há propriedades configuradas</v-alert>
          <v-divider class="my-6" />
          <v-form ref="form" v-model="valid" lazy-validation style="background-color: none;">
            <v-card-title class="pl-0 py-0">Inserir nova propriedade:</v-card-title>
            <v-text-field v-model="property" outlined label="Propriedade" :rules="[stringRules]" />
            <v-btn :disabled="!property || !valid" color="primary" block outlined @click="saveEditProperty('add')"
              ><v-icon class="mr-1">mdi-content-save</v-icon> SALVAR</v-btn
            >
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- add query -->
    <v-dialog v-if="addQueryDialog" v-model="addQueryDialog" max-width="900" persistent transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="closeAddQueryDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Adicionar Query</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-container class="pa-4">
          <v-form ref="form" v-model="valid" lazy-validation style="background-color: none;">
            <v-text-field v-model="newQueryKey" class="mb-0 mx-2 mt-2" outlined label="Nome da query" :rules="[stringRules]" />
            <v-switch v-model="query.inherit" hide-details color="primary" label="Inherit" class="pt-0 pb-1 pl-3 mt-0" />
            <query-builder-mongo :editable="true" :show="query.inherit" :sql.sync="query" class="mb-5 ml-3 mt-3" itemscope />
            <v-btn class="mt-4" :disabled="!newQueryKey || !valid" color="primary" block outlined @click="addMongoQueryManager"
              ><v-icon class="mr-1">mdi-content-save</v-icon> SALVAR</v-btn
            >
          </v-form>
          <change-dialog v-if="changesDialogAdd" :dialog.sync="changesDialogAdd" :message.sync="changesMessage" @proceed="addMongoQuery" />
        </v-container>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { MUTATE_EDIT_MONGO_QUERY, MUTATION_REMOVE_QUERY, MUTATE_EDIT_FIELDS_OUTPUT_ORDER, MUTATE_ADD_NEW_MONGO_QUERY } from '@/modules/accounts/graphql'

export default {
  components: {
    QueryBuilderMongo: () => import('@/components/base/QueryBuilderMongo'),
    ImportingSqlBtn: () => import('@/components/base/ImportingSqlBtn'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    accountId: String,
    dialog: {
      type: Boolean,
      default: false
    },
    server: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    },
    isHybrid: {
      type: Boolean,
      default: false
    },
    queryKeys: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    changesDialogEdit: false,
    changesDialogDelete: false,
    changesDialogAdd: false,
    changesMessage: '',
    fooDialog: false,
    addQueryDialog: false,
    property: '',
    key: null,
    valid: false,
    newQueryKey: '',
    query: {
      inherit: false,
      atemporal: true,
      realtime: '',
      query: {
        body: '',
        collection: '',
        database: '',
        date_field: '',
        date_field_format: '',
        fields_output_order: []
      }
    },
    stringRules: v => (!!v && v.trim() !== '') || 'Campo obrigatório'
  }),
  methods: {
    async saveQuery(key) {
      this.$setLogMessage(this.changesMessage)
      try {
        await this.$apollo.mutate({
          mutation: MUTATE_EDIT_MONGO_QUERY,
          variables: {
            id: this.accountId,
            key: key,
            index: this.server.index,
            query: this.server.configuration.sqls[key]
          }
        })

        this.$emit('update:server', this.server)
        this.$snackbar({ message: 'Query atualizada com sucesso', snackbarColor: '#2E7D32' })
        this.close()
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao atualizar query', snackbarColor: '#F44336' })
      }
    },
    async deleteQuery(key) {
      this.$setLogMessage(this.changesMessage)
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_QUERY,
          variables: {
            id: this.accountId,
            key,
            index: this.server.index
          }
        })

        delete this.server.configuration.sqls[key]
        this.$emit('update:server', this.server)
        this.$snackbar({ message: 'Query removida com sucesso', snackbarColor: '#2E7D32' })
        this.close()
        this.$router.go()
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover query', snackbarColor: '#F44336' })
      }
    },
    close() {
      return this.$emit('close')
    },
    saveEditManager(key) {
      this.key = key
      if (this.isDeploying) return this.saveQuery(key)
      this.changesDialogEdit = true
    },
    deleteEditManager(key) {
      this.key = key
      if (this.isDeploying) return this.deleteQuery(key)
      this.changesDialogDelete = true
    },
    openFooDialog(key) {
      this.key = key
      this.fooDialog = true
    },
    closeFooDialog() {
      this.fooDialog = false
      this.property = ''
    },
    closeAddQueryDialog() {
      this.addQueryDialog = false
    },
    async saveEditProperty(flag) {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Falha ao validar campo', snackbarColor: '#F44336' })

      const variables = {
        id: this.accountId,
        index: this.server.index,
        key: this.key,
        property: this.property.trim(),
        flag
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_EDIT_FIELDS_OUTPUT_ORDER,
          variables
        })
        this.server.configuration.sqls[variables.key].query?.fields_output_order?.push(variables.property)
        if (!this.server.configuration.sqls[variables.key].query?.fields_output_order) return this.$router.go()
        this.$emit('update:server', this.server)
        this.$snackbar({ message: 'Propriedade de saída inserida com sucesso', snackbarColor: '#2E7D32' })
        this.closeFooDialog()
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao inserir nova propriedade de saída', snackbarColor: '#F44336' })
      }
    },
    async deleteField(key, property, flag) {
      const variables = {
        id: this.accountId,
        index: this.server.index,
        key,
        property,
        flag
      }

      const propertyIndex = this.server.configuration.sqls[variables.key].query.fields_output_order.indexOf(variables.property)

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_EDIT_FIELDS_OUTPUT_ORDER,
          variables
        })
        this.server.configuration.sqls[variables.key].query.fields_output_order.splice(propertyIndex, 1)
        this.$emit('update:server', this.server)
        this.$snackbar({ message: 'Propriedade de saída removida com sucesso', snackbarColor: '#2E7D32' })
        this.closeFooDialog()
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover propriedade de saída', snackbarColor: '#F44336' })
      }
    },
    async addMongoQuery() {
      if (this.isHybrid && this.queryKeys.includes(this.newQueryKey)) {
        return this.$snackbar({ message: 'Um dos servidores já possui esta query', snackbarColor: '#F44336' })
      }

      this.$setLogMessage(this.changesMessage)
      const variables = {
        id: this.accountId,
        index: this.server.index,
        key: this.newQueryKey,
        query: this.query
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_ADD_NEW_MONGO_QUERY,
          variables
        })
        this.$emit('update:server', this.server)
        this.$snackbar({ message: 'Nova query inserida com sucesso', snackbarColor: '#2E7D32' })
        setTimeout(() => {
          this.$router.go()
        }, 500)
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao inserir nova query', snackbarColor: '#F44336' })
      }
    },
    addMongoQueryManager() {
      if (this.isDeploying) return this.addMongoQuery()
      this.changesDialogAdd = true
    }
  }
}
</script>
